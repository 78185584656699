 @font-face {
   font-family: Pro-Regular;
   src: url(/src/assets/font/SF-Pro-Regular.woff);
 }

 @font-face {
   font-family: Pro-Medium;
   src: url(/src/assets/font/SF-Pro-Medium.woff);
 }

 @font-face {
   font-family: Pro-Bold;
   src: url(/src/assets/font/SF-Pro-Bold.woff);
 }

 @font-face {
   font-family: Pro-Black;
   src: url(/src/assets/font/SF-Pro-Black.woff);
 }

 body {
   margin: 0;
   font-family: Pro-Regular;
   /* font-family: "Lato", sans-serif; */
   font-size: 14px;
 }



 .navbar {
   background: #fff;
   box-shadow: 0 3px 5px -5px rgba(0, 0, 0, .5);
 }

 .loginName {
   border: #f09539 solid 1px;
   border-radius: 3px;
 }

 .loginName .fa {
   margin-right: 10px;
 }

 .logoutTxt {
   color: #000000;
   text-decoration: none;
   cursor: pointer !important;
   color: #000000;
   font-weight: 500;
   font-size: 13px;
 }



 .navbar-collapse {
   flex-grow: 0;
 }

 .serach_panal_wrp {
   background: #1f538c;
   padding: 25px;
   color: #ffffff;
 }

 .serach_panal_wrp .IndexField {
   float: left;
   width: 19%;
   margin-right: 10px;
 }

 .serach_panal_wrp .fieldAddBtn {
   float: right;
   position: relative;
   top: -25px;
   right: -10px;
 }

 .serach_panal_wrp .fieldAddBtn .fa {
   font-size: 20px;
 }

 .yellow_serachBtn {
   border: #f09539 solid 1px;
   border-radius: 3px;
   background: #f09539;
   width: auto;
   height: 33px;
   color: #ffffff;
   font-weight: 500;
   margin-top: 26px;
   line-height: 20px;
 }

 .yellow_serachBtn:hover {
   background: #f09539;
   color: #ffffff;
 }

 .yellow_serachBtn .fa {
   margin-right: 10px;
 }

 .form-control,
 .form-select {
   border: #1f538c solid 1px;
   border-radius: 3px !important;
   font-size: 13px;
 }

 .Select_Company {
   margin: 0px;
 }

 .Select_Company .input-group-text {
   padding: 0px 10px 0px 0px;
 }

 .Select_Company .form-select {
   border: #000000 solid 1px !important;
   border-radius: 3px !important;
   font-size: 13px;
 }

 .Select_Company .input-group-text {
   border: #ffffff solid 1px !important;
   border-radius: 3px !important;
   font-size: 13px;
   background: #ffffff;
   font-size: 15px;
   font-weight: 500;
 }

 .Txt_bold {
   font-size: 15px;
   font-weight: 500;
 }

 .page_wrp {
   margin-top: 50px;
 }

 .page_title {
   background: #1f538c;
   color: #ffffff;
   display: inline-block;
   padding: 5px 10px;
   font-size: 15px;
   font-weight: 500;
 }

 .tab_wrp {
   border: #1f538c solid 1px;
   background: #ffffff;
   box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
   padding: 20px;
 }

 .tab_box {
   border: #1f538c solid 1px;
   padding: 20px;
 }

 .Tab_button_wrp {
   margin: 0px;
 }

 .Tab_button_wrp button {
   background: #ffffff;
   font-size: 14px;
   font-weight: 500;
   border: #1f538c solid 1px;
   border-bottom: none;
   padding: 5px 10px;
   margin-right: 2px;
   border-radius: 0px;

 }

 .Tab_button_wrp button:hover {
   background: #1f538c;
   color: #ffffff;

 }

 .Tab_button_wrp button:hover .Active {
   background: #1f538c;
   color: #ffffff;
 }

 .tabActiveBtn {
   background: #1f538c !important;
   color: #ffffff;
 }

 /* .Tab_button_wrp button:nth-child {
  border-left: none;
  border-right: none;
} */

 .company_logo_wrp {
   border: #1f538c solid 1px;
   height: 215px;
   /*width: 215px;*/
   width: 100%;
   text-align: center;
 }

 .company_logo_wrp label {
   font-weight: 500;
   cursor: pointer;

 }

 .company_logo_wrp img {
   display: inline-block;
   max-width: 100%;
   height: 175px;
   margin-top: 10px;
 }

 .addUserBtn,
 .editBtn,
 .deleteBtn {
   background: #0e5682;
   padding: 5px 10px;
   border-radius: 3px;
   color: #ffffff;
   font-size: 14px;
   margin-bottom: 10px;
   border: none;
 }

 .addUserBtn:hover {
   background: #0e5682;
   color: #ffffff;
 }

 .editBtn {
   background: #ffffff;
   border: #1f538c solid 1px;
   color: #000000;
   margin-bottom: 0px;
   margin-left: 2px;
 }

 .editBtn:hover {
   border: #1f538c solid 1px;
   box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
 }

 .deleteBtn {
   border: 1px solid #a5a5a5;
   box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
   background: #ffffff;
   color: #000000;
   margin-bottom: 10px;
 }

 .sendBtn {
   margin-left: 10px;
 }

 .deleteBtn:hover {
   border: 1px solid #a5a5a5;
 }

 .addUserBtn .fa,
 .editBtn .fa,
 .deleteBtn .fa {
   margin-right: 5px;
 }

 .table-bordered {
   margin-bottom: 0px;
 }

 .blueTbl {
   margin: 0px;
 }

 .blueTbl th {
   background: #1f538c !important;
   border-bottom: none;
   border-left: none;
   padding: 5px 5px;
   font-size: 14px;
   color: #ffffff;
   font-weight: 600;
   vertical-align: baseline;
 }

 .blueTbl td {
   padding: 5px 5px;
   font-size: 13px;
   vertical-align: middle;
   word-break: break-all;
   font-weight: normal;
 }

 .saveBtn {
   background: #ffffff;
   border: #1f538c solid 1px;
   padding: 3px 10px;
   border-radius: 3px;
   color: #000000;
   font-size: 14px;
   margin-bottom: 10px;
   border-radius: 0px;
 }

 .saveBtn:hover {
   border: #1f538c solid 1px;
   color: #000000;
 }

 .grayBox {
   border: #a5a5a5 solid 1px;
   background: #f9f9f9;
   padding: 20px;
 }

 .grayBox label {
   font-size: 13px;
   margin-bottom: 5px;
 }

 .backBtn,
 .nextBtn,
 .displayAllBtn {
   background: #1f538c;
   border-radius: 3px;
   padding: 8px 30px;
   color: #ffffff;
   font-size: 13px;
   margin-top: 20px;
   font-weight: 500;
 }

 .nextBtn {
   margin-left: 10px;

 }

 .nextBtn:hover {
   background: #1f538c;
   color: #ffffff;

 }

 .backBtn {
   background: #ffffff;
   border: #1f538c solid 1px;
   color: #000000;
 }

 .backBtn:hover {
   background: #ffffff;
   border: #1f538c solid 1px;
   color: #000000;
 }

 .displayAllBtn {
   margin-top: 0px;
   border: none;
 }

 .displayAllBtn:hover {
   background: #1f538c;
   color: #ffffff;
 }

 .contant_box_wrp {
   border: #1f538c solid 1px;
   background: #ffffff;
   box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
   padding: 20px;
 }

 .fa-file-image-o {
   color: #bcbbbb;
   margin: 25px 0px 10px 0px;
   font-size: 135px;
 }

 .manageCompanyWrp {
   margin: 0px;
 }

 .manageCompanyWrp .Select_Company {
   float: left;
   width: 285px;
   margin-right: 10px;
 }

 .manageCompanyWrp .active {
   float: left;
   width: 200px;
 }

 .manageCompanyWrp .Act_div {
   float: right;
   width: 130px;
 }

 .activatedBtn {
   background: #198754;
   padding: 2px 16px;
   border: none;
   border-radius: 12px;
   color: #ffffff;
   font-size: 12px;
   margin-top: 7px;
   cursor: crosshair;
 }

 .updateBtn,
 .saveBtn {
   background: #1f538c;
   border: #1f538c solid 1px;
   padding: 8px 20px;
   color: #ffffff;
   font-size: 13px;
   font-weight: 500;
   border-radius: 3px;
 }

 .updateBtn:hover,
 .saveBtn:hover {
   background: #1f538c;
   color: #ffffff;
 }

 .notFoundWrp {
   margin: 0px;
 }

 .notFound {
   font-size: 20px;
   background: #ffffff;
   margin: 30px auto;
   font-size: 14px;
   padding: 5px 10px;
   border: #000000 dashed 1px;
   font-weight: 500;
   width: 25%;
   text-align: center;
   padding: 30px 0px;
 }

 .notFound img {
   margin-bottom: 20px;
 }

 .notFound h1 {
   font-size: 20px;
   font-weight: 500;
   margin-bottom: 0px;
 }

 .notFound h2 {
   font-size: 18px;
   margin-bottom: 0px;
 }

 .notFound p {
   font-size: 14px;
   margin-top: 10px;
   font-weight: normal;
 }

 .addIndexFieldPanal {
   margin: 0;
 }

 .addIndexFieldPanal .div1,
 .addIndexFieldPanal .div2,
 .addIndexFieldPanal .div3,
 .addIndexFieldPanal .div4 {
   float: left;
   width: 20%;
   margin-right: 10px;
 }



 .addIndexFieldPanal .div5 {
   float: left;
   width: 11%;
   border: #1f538c solid 1px;
   background: #ffffff;
   border-radius: 3px;
   padding: 5px 10px 2px 10px;
   position: relative;
   margin-top: 26px;
 }

 .addIndexFieldPanal .Required {

   padding: 5px 10px 2px 10px;
   position: relative;
   margin-top: 0px !important;
 }

 .addIndexFieldPanal .div5 .form-check {
   margin-bottom: 0px;
 }

 .addIndexFieldPanal .div5 label {
   margin-bottom: 0px;
 }

 .addIndexFieldPanal .div6 {
   float: right;
   width: 4%;
   position: relative;
   top: 26px;
 }

 .addIndexFieldPanal .removemarginTop {
   top: 0px;
 }

 .BuleClor {
   background: #1f538c !important;
   border: none !important;
   padding: 5px 10px 5px 10px;
 }

 .uploadLogoBtn {
   text-align: center;
 }

 .uploadLogoText {
   margin-top: 5px;
   cursor: pointer !important;
   text-align: center;
 }

 .uploadLogoText .fa {
   margin-right: 5px;
 }

 label {
   font-weight: 500;
   margin-bottom: 5px;
 }

 .MuiTypography-root {
   font-size: 14px !important;
   text-align: right;
 }

 .nav-link {
   color: #000000;
   font-size: 14px;
   font-weight: 500;
 }

 .dropdown .dropdown-item {
   color: #000000;
   font-weight: 500;
   font-size: 13px;
   padding: 10px 10px;
   border-bottom: #a5a5a5 solid 1px;
 }

 .dropdown .dropdown-item .fa {
   margin-right: 5px;
 }

 .dropdown-menu {
   border-radius: 0px;
   padding: 0px;
   box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
 }

 .underdevelopment {
   font-size: 20px;
   text-align: center;
   font-weight: 500;

 }

 .underdevelopment h1 {
   margin-top: 20px;
 }

 .tableScroll {
   max-height: 295px;
   overflow: auto;
   overflow-y: auto;
   overflow-x: auto;
   margin-bottom: 20px;
   border: #dddddd solid 1px;


 }

 .companyMenu .dropdown-menu {
   left: -30px;
   top: 40px;
 }

 .adminMenu .dropdown-menu {
   left: -30px;
   top: 40px;
 }

 .loginName .dropdown-menu {
   left: -1px;
   top: 37px;

   width: 177px;
 }



 /* HTML: <div class="loader"></div> */
 .loaderWrp {
   margin: 100px auto;
   width: 250px;
 }

 .loader {
   width: fit-content;
   font-weight: 500;
   font-size: 30px;
   background: radial-gradient(circle closest-side, #1f538c 94%, #0000) right/calc(200% - 1em) 100%;
   animation: l24 1s infinite alternate linear;
 }

 .loader::before {
   content: "Loading...";
   line-height: 1em;
   color: #0000;
   background: inherit;
   background-image: radial-gradient(circle closest-side, #fff 94%, #1f538c);
   -webkit-background-clip: text;
   background-clip: text;
 }

 @keyframes l24 {
   100% {
     background-position: left
   }
 }

 .footerBtnGroup {
   float: right;
 }

 .workInProgress {
   width: 500px;
   margin: 0 auto;
   text-align: center;
 }

 .workInProgress h2 {
   font-size: 18px;
   font-weight: 500;
   margin-top: 10px;
 }

 .deletePageAddWrp {
   margin: 0px;
 }

 .deletePageAddWrp .div1 {
   float: left;
   width: 9%;
 }

 .deletePageAddWrp .div1 .form-control {
   display: inline;
   position: relative;
   left: 3px;
   width: 50%;
 }

 .deletePageAddWrp .div2 {
   float: left;
 }

 .deletePageAddWrp .div3 {
   float: right;
 }

 .editIndexFieldPanal {
   margin: 0;
 }

 .editIndexFieldPanal .div1,
 .editIndexFieldPanal .div2,
 .editIndexFieldPanal .div3,
 .editIndexFieldPanal .div4,
 .editIndexFieldPanal .div5 {
   float: left;
   width: 21%;
   margin-right: 10px;
 }

 .editIndexFieldPanal .div5 {
   float: left;
   width: 10%;
   border: #1f538c solid 1px;
   background: #ffffff;
   border-radius: 3px;
   padding: 5px 10px 2px 10px;
   position: relative;
   margin-top: 26px;
 }

 .editIndexFieldPanal .div5 label {
   margin-left: 5px;
 }

 .bottom_Pagination_Wrp {
   float: right;
 }

 .Pagination {
   background: #0e5682;
   margin-bottom: 0px;
 }

 .Pagination li {
   background: #0e5682;
   margin-bottom: 0px;
 }

 .active>.page-link,
 .page-link.active {
   z-index: 3;
   color: var(--bs-pagination-active-color);
   background-color: #0e5682;
   border-color: #0e5682;
 }

 .page-link {
   padding: 3px 10px;
   border-radius: 0px;
 }

 .btn-danger {
   background: #fff !important;
   box-shadow: 0 0 3px 0 #0000001a !important;
   border: #575656 solid 1px !important;
   color: #4e4d4d !important;
 }

 .backTopBtn,
 .searchBtn {
   border-radius: 3px;
   background: #f09539;
   margin-left: 5px;
   color: #ffffff;
   font-size: 13px;
   font-weight: 500;
   padding: 8px 20px;
   margin-top: 5px;
 }

 .backTopBtn:hover {
   border: #f09539 solid 1px;
   background: #f09539;
   color: #ffffff;
 }

 .mgnTop-0 {
   margin-top: 0px;
 }

 .mgnlFt-5 {
   margin-left: 5px;
 }

 .searchBtn {
   background: #0e5682;
   border: none;
 }

 .searchBtn:hover {
   background: #0e5682;
   border: none;
 }


 .serachDocWrp {
   background-color: #0e5682;
   border: none;
   padding: 20px;
   color: #ffffff;
 }


 .serachDocWrp .addIndexFieldPanal {
   margin: 0;
 }

 .serachDocWrp .addIndexFieldPanal .div1,
 .serachDocWrp .addIndexFieldPanal .div2,
 .serachDocWrp .addIndexFieldPanal .div3,
 .serachDocWrp .addIndexFieldPanal .div4 {
   float: left;
   width: 23%;
   margin-right: 10px;
 }



 .addIndexFieldPanal .div5 {
   float: left;
   width: 11%;
   border: #1f538c solid 1px;
   background: #ffffff;
   border-radius: 3px;
   padding: 5px 10px 2px 10px;
   position: relative;
   margin-top: 26px;
 }



 .addIndexFieldPanal .div5 label {
   margin-bottom: 0px;
 }

 .addIndexFieldPanal .div6 {
   float: right;
   width: 4%;
   position: relative;
   top: 26px;
 }

 .addIndexFieldPanal .div6 .fa {
   font-size: 20px;
   color: #ffffff;
 }

 .addIndexFieldPanal .removemarginTop {
   top: 0px;
 }



 .btn.disabled {
   border: #6c6b6b solid 1px;
 }

 .displayAllWrp {
   margin: 0px;
 }

 .blueColor {
   color: #0e5682 !important;
 }

 #alert-description {
   padding: 0px 20px;
 }

 .searchResultTbl {
   max-height: 542px;
   overflow: auto;
   overflow-y: auto;
   overflow-x: auto;
   margin-bottom: 20px;
   border: #dddddd solid 1px;


 }

 .searchResultTbl table {
   border-collapse: collapse;
   width: 100%;
 }

 .searchResultTbl table th {
   border: 1px solid #ddd;
   padding: 8px;
   text-align: left;
   white-space: nowrap;
   /* Prevents wrapping */
   width: 1%;
   /* Auto-shrink to fit content */
   background: #1f538c !important;
   color: #ffffff;
 }

 .searchResultTbl table td {
   border: 1px solid #ddd;
   padding: 8px;
   text-align: left;
   white-space: nowrap;
   /* Prevents wrapping */
   width: 1%;
   /* Auto-shrink to fit content */
 }

 .approveBtn {
   background: #ffffff;
   border: #26d000 solid 1px;
   color: #26d000;
   margin-bottom: 0px;
   font-size: 13px;
   margin-left: 2px;
   border-radius: 3px;

 }

 .rejectBtn {
   background: #ffffff;
   border: #d50909 solid 1px;
   color: #d50909;
   margin-bottom: 0px;
   font-size: 13px;
   margin-left: 2px;
   border-radius: 3px;

 }


 .approveBtn:hover {
   border: #26d000 solid 1px;
   color: #26d000;
   cursor: pointer !important;
   box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
 }

 .rejectBtn:hover {
   border: #d50909 solid 1px;
   cursor: pointer !important;
   color: #d50909;
   box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
 }


 .sendFileTbl {
   max-height: 458px;
   overflow: auto;
   overflow-y: auto;
   overflow-x: auto;
   margin-bottom: 20px;
   border: #dddddd solid 1px;
 }

 .sendFileTbl table {
   border-collapse: collapse;
   width: 100%;
 }

 .sendFileTbl table th {
   border: 1px solid #ddd;
   padding: 8px;
   text-align: left;
   white-space: nowrap;
   /* Prevents wrapping */
   width: 1%;
   /* Auto-shrink to fit content */
   background: #1f538c !important;
   color: #ffffff;
   font-size: 12px;
 }

 .sendFileTbl table td {
   border: 1px solid #ddd;
   padding: 8px;
   text-align: left;
   white-space: nowrap;
   /* Prevents wrapping */
   width: 1%;
   /* Auto-shrink to fit content */
   font-size: 12px;
 }

 .padd10 {
   padding: 10px;
 }

 .email_Form .form-label {
   margin-bottom: .0rem;
   font-size: 12px;
   font-weight: bold;
   color: #000000;
 }

 .email_Form .form-control {
   font-size: 12px;
   color: #000000;
 }

 .SendEmailBtnGroup button {
   float: right;
   margin-left: 2px;
   font-size: 14px;
   border-radius: 3px;
   margin-top: 5px;
 }

 .deletePageAddWrp .fa {
   margin-right: 5px;
 }

 .css-1y5vrnu-MuiButtonBase-root-MuiCheckbox-root {
   padding: 0px !important;
 }


 .pendingReqstTbl {
   max-height: 500px;
   overflow: auto;
   overflow-y: auto;
   overflow-x: auto;
   margin-bottom: 20px;
   border: #dddddd solid 1px;


 }

 .pendingReqstTbl table {
   border-collapse: collapse;
   width: 100%;
 }

 .pendingReqstTbl table th {
   border: 1px solid #ddd;
   padding: 5px 8px;
   text-align: left;
   white-space: nowrap;
   /* Prevents wrapping */
   width: 1%;
   /* Auto-shrink to fit content */
   background: #1f538c !important;
   color: #ffffff;
 }

 .pendingReqstTbl table td {
   border: 1px solid #ddd;
   padding: 5px 8px;
   text-align: left;
   white-space: nowrap;
   /* Prevents wrapping */
   width: 1%;
   /* Auto-shrink to fit content */
 }

 .companyMailLogo img {
   display: inline-block;
   display: inline-block;
   width: 50%;
   margin-left: 45px;
   margin-top: 10px;
   border: #dddddd solid 1px;
   padding: 5px;
 }

 .RetentionBox {
   margin: 0px;
 }

 .RetentionBox {
   margin: 0px;
 }

 .RetentionBox h2 {
   font-size: 12px;
   font-weight: 600;
 }

 .RetentionBox .tab_wrp {
   border: #a5a5a5 solid 1px;
 }

 .RetentionBox .tableScroll {
   margin-bottom: 0px;
 }

 .ImgProfileGrayBox {
   border: #a5a5a5 solid 1px;
   background: #f9f9f9;
   padding: 20px;
 }

 .ImgProfileGrayBox label {
   font-size: 12px;
   margin-bottom: 5px;
   font-weight: 600;
 }

 .ImgProfileGrayBox .col-md-2 {
   padding-left: 3px;
   padding-right: 3px;
 }

 #userProductivity hr {
   margin: 0px;
   margin-bottom: 10px;
 }

 .pendingRuqustSerch {
   margin: 0px;
   float: right;
 }

 .pendingRuqustSerch .searchBtn {
   margin-bottom: 10px;
 }

 .pendingRuqustSerch .searchBtn:hover {
   color: #ffffff;
 }

 .SerchPopupWrp {
   width: 350px;
   position: fixed;
   top: 58%;
   right: -2%;
   transform: translate(-50%, -50%);
   padding: 20px;
   background-color: #ffffff;
   border: #0e5682 solid 1px;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
   z-index: 1000;
 }

 .SerchPopupWrp h6 {
   font-size: 13px;

 }

 .DocumentSerchModal {
   padding: 10px !important;
 }

 /* .modal-footer {
   justify-content: left !important;
 } */

 .DocumentSerchFooter {
   margin: 0px;
 }

 .DocumentSerchFooter .backBtn,
 .DocumentSerchFooter .nextBtn {
   margin-top: 0px !important;
 }