/* CustomAlert.css */
.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.customAlertContent {
  background: #fff;
  padding: 50px 70px;

  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: slideDown 0.4s ease-out;
  position: relative;
  top: -50px;
}

.customAlertContent h2 {
  font-size: 16px;
  font-weight: 600;
}

.customAlertContent p {
  font-size: 16px;
  font-weight: 600;
}

.customAlertFooter {
  margin-top: 20px;
}

.laterBTn {
  background: #ffffff;
  border: #1f538c solid 1px;
  padding: 5px 20px;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 10px;
}

.laterBTn:hover {
  background: #ffffff;
  border: #1f538c solid 1px;
}

.yesBtn {
  background: #1f538c;
  border: #1f538c solid 1px;
  padding: 5px 20px;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
}

.yesBtn:hover {
  background-color: #1f538c;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateY(0);
  }
}