.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: auto;
}

.modal .modal-dialog {
  z-index: 1060;
  top: 50px;
}

.for-flex {
  height: 12vh;
  display: flex;
}

.speedo {
  margin-top: 0px;
}

.emission-info {
  text-align: center;
  margin-top: 20px;
}

.emission-info p {
  margin-left: 0px;
}

.CancelBtn {
  background: #ffffff;
  border: #1f538c solid 1px;
  padding: 8px 20px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 10px;
  min-width: 70px;
}

.CancelBtn:hover {
  background: #ffffff;
  border: #1f538c solid 1px;
}

.okBtn {
  background: #1f538c;
  border: #1f538c solid 1px;
  padding: 8px 20px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  color: #ffffff;
  min-width: 80px;
}

.okBtn:hover {
  background-color: #1f538c;
}

.modal-footer {
  justify-content: center;
  border-top: none;
  padding-bottom: 20px;
}

.modal-header {
  border-bottom: none;
  justify-content: flex-end;
}

.closeBtn {
  border: none;
  background: none;
  color: #000000;
}

.closeBtn .fa {
  font-size: 25px;
}

.modal-body {
  padding: 0px 0px;
}

.modal-body p {
  font-size: 15px;
  font-weight: 500;
}

@media (max-width: 460px) {
  .emission-info {
    font-size: 2rem;
  }

  .for-flex {
    flex-direction: column;
    height: auto;
  }

  .speedo {
    margin-top: 10px;
  }

  .emission-info p {
    margin-top: -90px;
    font-size: 18px;
  }



}